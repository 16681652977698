import React from 'react';
import Home from '../../components/home/v3/Home';
import Layout from '../../layouts/index';

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { props } = this;
    return (
      <Layout routeSlug="HomePage">
        <Home {...props} altTag="retouche couture avec Tilli" />
      </Layout>
    );
  }
}

export default HomePage;
